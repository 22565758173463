

















































import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import { Meeting, Contact } from '@tada-team/tdproto-ts'
import { format } from 'quasar'
import { getTime, parseDate } from '@quasar/quasar-ui-qcalendar'

@Component({
  components: {
    Actions: () => import('./Actions.vue'),
    BaseAvatar: () => import('@/components/UI/BaseAvatar.vue'),
    IconRepeat2: () => import('@tada/icons/dist/IconRepeat2.vue'),
  },
})
export default class MeetingHeader extends Vue {
  @VModel({
    required: true,
    type: Boolean,
  }) importantViewerActive!: boolean

  @Prop({
    required: true,
    type: Meeting,
  }) readonly meeting!: Meeting

  @Prop({
    required: true,
    type: Contact,
  }) readonly owner!: Contact

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  }) readonly withTitle!: boolean

  get meetingDate () {
    const date = format.capitalize(new Intl.DateTimeFormat(this.$i18n.locale, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    }).format(new Date(this.meeting.startAt)))
    return `${date} ${this.timeInterval}`
  }

  get timeInterval () {
    const start = new Date(this.meeting.startAt)
    const end = new Date(start.getTime() + this.meeting.duration * 60000)
    const startTimestamp = parseDate(start)
    const endTimestamp = parseDate(end)
    if (!(startTimestamp && endTimestamp)) return ''
    return `${getTime(startTimestamp) || '00:00'} — ${getTime(endTimestamp) || '00:00'}`
  }
}
